define("discourse/plugins/discourse-locations/discourse/components/replace-location", ["exports", "@glimmer/component", "@ember/service", "discourse/helpers/body-class", "discourse-common/helpers/d-icon", "discourse/plugins/discourse-locations/discourse/components/user-location", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _bodyClass, _dIcon, _userLocation, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ReplaceLocationComponent extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.inject]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    get showUserLocation() {
      return !!this.args.model.custom_fields?.geo_location && this.args.model.custom_fields?.geo_location !== "{}";
    }
    get linkWebsite() {
      return !this.args.model.isBasic;
    }
    get removeNoFollow() {
      return this.args.model.trust_level > 2 && !this.siteSettings.tl3_links_no_follow;
    }
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{bodyClass "map-location-enabled"}}
        {{#if this.showUserLocation}}
          <div class="user-profile-location">
            <UserLocation @user={{@model}} @formFactor="profile" />
          </div>
        {{/if}}
        {{#if @model.website_name}}
          <div class="user-profile-website">
            {{icon "globe"}}
            {{#if this.linkWebsite}}
              <a
                href={{@model.website}}
                rel={{unless this.removeNoFollow "nofollow ugc noopener"}}
                target="_blank"
              >
                {{@model.website_name}}
              </a>
            {{else}}
              <span title={{@model.website}}>{{@model.website_name}}</span>
            {{/if}}
          </div>
        {{/if}}
      
    */
    {
      "id": "yel/Sb8d",
      "block": "[[[1,\"\\n    \"],[1,[28,[32,0],[\"map-location-enabled\"],null]],[1,\"\\n\"],[41,[30,0,[\"showUserLocation\"]],[[[1,\"      \"],[10,0],[14,0,\"user-profile-location\"],[12],[1,\"\\n        \"],[8,[32,1],null,[[\"@user\",\"@formFactor\"],[[30,1],\"profile\"]],null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[41,[30,1,[\"website_name\"]],[[[1,\"      \"],[10,0],[14,0,\"user-profile-website\"],[12],[1,\"\\n        \"],[1,[28,[32,2],[\"globe\"],null]],[1,\"\\n\"],[41,[30,0,[\"linkWebsite\"]],[[[1,\"          \"],[10,3],[15,6,[30,1,[\"website\"]]],[15,\"rel\",[52,[51,[30,0,[\"removeNoFollow\"]]],\"nofollow ugc noopener\"]],[14,\"target\",\"_blank\"],[12],[1,\"\\n            \"],[1,[30,1,[\"website_name\"]]],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]],[[[1,\"          \"],[10,1],[15,\"title\",[30,1,[\"website\"]]],[12],[1,[30,1,[\"website_name\"]]],[13],[1,\"\\n\"]],[]]],[1,\"      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"@model\"],false,[\"if\",\"unless\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-locations/discourse/components/replace-location.js",
      "scope": () => [_bodyClass.default, _userLocation.default, _dIcon.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ReplaceLocationComponent;
});