define("discourse/plugins/discourse-locations/discourse/components/add-location-controls", ["exports", "discourse/plugins/discourse-locations/discourse/lib/location-utilities", "discourse-common/utils/decorators", "@ember/component", "@ember/service", "discourse/plugins/discourse-locations/discourse/components/modal/add-location"], function (_exports, _locationUtilities, _decorators, _component, _service, _addLocation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    modal: (0, _service.inject)(),
    classNames: ["location-label"],
    didInsertElement() {
      $(".title-and-category").toggleClass("location-add-no-text", this.get("iconOnly"));
    },
    iconOnly(noText) {
      return noText || this.siteSettings.location_add_no_text;
    },
    valueClasses(noText) {
      let classes = "add-location-btn";
      if (noText) {
        classes += " btn-primary";
      }
      return classes;
    },
    valueLabel(location, noText) {
      return noText ? "" : (0, _locationUtilities.locationFormat)(location, this.site.country_codes, this.siteSettings.location_input_fields_enabled, this.siteSettings.location_input_fields);
    },
    addLabel(noText) {
      return noText ? "" : "composer.location.btn";
    },
    actions: {
      showAddLocation() {
        return this.modal.show(_addLocation.default, {
          model: {
            location: this.get("location"),
            categoryId: this.get("category.id"),
            update: location => {
              if (this._state !== "destroying") {
                this.set("location", location);
              }
            }
          }
        });
      },
      removeLocation() {
        this.set("location", null);
      }
    }
  }, [["method", "iconOnly", [(0, _decorators.default)("noText")]], ["method", "valueClasses", [(0, _decorators.default)("noText")]], ["method", "valueLabel", [(0, _decorators.default)("location", "noText")]], ["method", "addLabel", [(0, _decorators.default)("noText")]]]));
});