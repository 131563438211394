define("discourse/plugins/discourse-locations/discourse/connectors/user-custom-preferences/map-location", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    actions: {
      searchError(error) {
        this.set("error", error);
      }
    }
  };
});