define("discourse/plugins/discourse-locations/discourse/templates/components/add-location-controls", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.location}}
    <DButton
      @class={{this.valueClasses}}
      @title="location.label.add"
      @action={{action "showAddLocation"}}
      @translatedLabel={{this.valueLabel}}
      @icon="map-marker-alt"
    />
    {{#unless this.noText}}
      <DButton
        @icon="times"
        @action={{action "removeLocation"}}
        @class="remove"
      />
    {{/unless}}
  {{else}}
    {{#if this.iconOnly}}
      <DButton
        @class="add-location-btn"
        @icon="map-marker-alt"
        @action={{action "showAddLocation"}}
        @title={{this.addLabel}}
      />
    {{else}}
      <DButton
        @class="add-location-btn"
        @icon="map-marker-alt"
        @label={{this.addLabel}}
        @action={{action "showAddLocation"}}
        @title={{this.addLabel}}
      />
    {{/if}}
  {{/if}}
  */
  {
    "id": "qe892Mid",
    "block": "[[[41,[30,0,[\"location\"]],[[[1,\"  \"],[8,[39,1],null,[[\"@class\",\"@title\",\"@action\",\"@translatedLabel\",\"@icon\"],[[30,0,[\"valueClasses\"]],\"location.label.add\",[28,[37,2],[[30,0],\"showAddLocation\"],null],[30,0,[\"valueLabel\"]],\"map-marker-alt\"]],null],[1,\"\\n\"],[41,[51,[30,0,[\"noText\"]]],[[[1,\"    \"],[8,[39,1],null,[[\"@icon\",\"@action\",\"@class\"],[\"times\",[28,[37,2],[[30,0],\"removeLocation\"],null],\"remove\"]],null],[1,\"\\n\"]],[]],null]],[]],[[[41,[30,0,[\"iconOnly\"]],[[[1,\"    \"],[8,[39,1],null,[[\"@class\",\"@icon\",\"@action\",\"@title\"],[\"add-location-btn\",\"map-marker-alt\",[28,[37,2],[[30,0],\"showAddLocation\"],null],[30,0,[\"addLabel\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"    \"],[8,[39,1],null,[[\"@class\",\"@icon\",\"@label\",\"@action\",\"@title\"],[\"add-location-btn\",\"map-marker-alt\",[30,0,[\"addLabel\"]],[28,[37,2],[[30,0],\"showAddLocation\"],null],[30,0,[\"addLabel\"]]]],null],[1,\"\\n\"]],[]]]],[]]]],[],false,[\"if\",\"d-button\",\"action\",\"unless\"]]",
    "moduleName": "discourse/plugins/discourse-locations/discourse/templates/components/add-location-controls.hbs",
    "isStrictMode": false
  });
});