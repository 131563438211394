define("discourse/plugins/discourse-locations/discourse/templates/modal/add-location", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModalBody @class="add-location" @title={{this.title}}>
    <LocationForm
      @street={{this.street}}
      @neighbourhood={{this.neighbourhood}}
      @postalcode={{this.postalcode}}
      @city={{this.city}}
      @state={{this.state}}
      @countrycode={{this.countrycode}}
      @geoLocation={{this.geoLocation}}
      @rawLocation={{this.rawLocation}}
      @inputFields={{this.inputFields}}
      @searchOnInit={{this.searchOnInit}}
      @setGeoLocation={{action "setGeoLocation"}}
      @searchError={{action "searchError"}}
    />
    <hr />
    <div class="control-group">
      <label class="control-label">{{i18n "location.name.title"}}</label>
      <div class="controls">
        <Input
          @type="text"
          @value={{this.name}}
          class="input-xxlarge input-location location-name"
        />
      </div>
      <div class="instructions">{{i18n "location.name.desc"}}</div>
    </div>
  </DModalBody>
  
  <div class="modal-footer">
    <DButton
      id="save-location"
      @action={{action "submit"}}
      @label="location.done"
      @class="btn-primary"
      @disabled={{this.submitDisabled}}
    />
    <DButton
      id="clear-location"
      @class="clear"
      @action={{action "clear"}}
      @label="location.clear"
    />
  </div>
  */
  {
    "id": "utMFRMkz",
    "block": "[[[8,[39,0],null,[[\"@class\",\"@title\"],[\"add-location\",[30,0,[\"title\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,1],null,[[\"@street\",\"@neighbourhood\",\"@postalcode\",\"@city\",\"@state\",\"@countrycode\",\"@geoLocation\",\"@rawLocation\",\"@inputFields\",\"@searchOnInit\",\"@setGeoLocation\",\"@searchError\"],[[30,0,[\"street\"]],[30,0,[\"neighbourhood\"]],[30,0,[\"postalcode\"]],[30,0,[\"city\"]],[30,0,[\"state\"]],[30,0,[\"countrycode\"]],[30,0,[\"geoLocation\"]],[30,0,[\"rawLocation\"]],[30,0,[\"inputFields\"]],[30,0,[\"searchOnInit\"]],[28,[37,2],[[30,0],\"setGeoLocation\"],null],[28,[37,2],[[30,0],\"searchError\"],null]]],null],[1,\"\\n  \"],[10,\"hr\"],[12],[13],[1,\"\\n  \"],[10,0],[14,0,\"control-group\"],[12],[1,\"\\n    \"],[10,\"label\"],[14,0,\"control-label\"],[12],[1,[28,[35,3],[\"location.name.title\"],null]],[13],[1,\"\\n    \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n      \"],[8,[39,4],[[24,0,\"input-xxlarge input-location location-name\"]],[[\"@type\",\"@value\"],[\"text\",[30,0,[\"name\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"instructions\"],[12],[1,[28,[35,3],[\"location.name.desc\"],null]],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\\n\"],[10,0],[14,0,\"modal-footer\"],[12],[1,\"\\n  \"],[8,[39,5],[[24,1,\"save-location\"]],[[\"@action\",\"@label\",\"@class\",\"@disabled\"],[[28,[37,2],[[30,0],\"submit\"],null],\"location.done\",\"btn-primary\",[30,0,[\"submitDisabled\"]]]],null],[1,\"\\n  \"],[8,[39,5],[[24,1,\"clear-location\"]],[[\"@class\",\"@action\",\"@label\"],[\"clear\",[28,[37,2],[[30,0],\"clear\"],null],\"location.clear\"]],null],[1,\"\\n\"],[13]],[],false,[\"d-modal-body\",\"location-form\",\"action\",\"i18n\",\"input\",\"d-button\"]]",
    "moduleName": "discourse/plugins/discourse-locations/discourse/templates/modal/add-location.hbs",
    "isStrictMode": false
  });
});