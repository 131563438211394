define("discourse/plugins/discourse-locations/discourse/helpers/geo-location-format", ["exports", "@ember/template", "discourse/plugins/discourse-locations/discourse/lib/location-utilities", "discourse/models/site"], function (_exports, _template, _locationUtilities, _site) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _geoLocationFormat;
  function _geoLocationFormat(geoLocation, opts) {
    return (0, _template.htmlSafe)((0, _locationUtilities.geoLocationFormat)(geoLocation, _site.default.currentProp("country_codes"), opts));
  }
});