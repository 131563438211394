define("discourse/plugins/discourse-locations/discourse/templates/discovery/map", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <Discovery::Navigation
    @category={{this.model.category}}
    @tag={{this.model.tag}}
    @additionalTags={{this.additionalTags}}
    @filterType={{this.model.filterType}}
    @noSubcategories={{this.model.noSubcategories}}
    @canBulkSelect={{this.canBulkSelect}}
    @bulkSelectHelper={{this.bulkSelectHelper}}
    @createTopic={{this.createTopic}}
    @createTopicDisabled={{this.createTopicDisabled}}
    @canCreateTopicOnTag={{this.canCreateTopicOnTag}}
    @toggleTagInfo={{this.toggleTagInfo}}
    @tagNotification={{this.tagNotification}}
  />
  <div class="map-component map-container">
    <LocationsMap @mapType="topicList" />
  </div>
  */
  {
    "id": "FAeYPwnD",
    "block": "[[[8,[39,0],null,[[\"@category\",\"@tag\",\"@additionalTags\",\"@filterType\",\"@noSubcategories\",\"@canBulkSelect\",\"@bulkSelectHelper\",\"@createTopic\",\"@createTopicDisabled\",\"@canCreateTopicOnTag\",\"@toggleTagInfo\",\"@tagNotification\"],[[30,0,[\"model\",\"category\"]],[30,0,[\"model\",\"tag\"]],[30,0,[\"additionalTags\"]],[30,0,[\"model\",\"filterType\"]],[30,0,[\"model\",\"noSubcategories\"]],[30,0,[\"canBulkSelect\"]],[30,0,[\"bulkSelectHelper\"]],[30,0,[\"createTopic\"]],[30,0,[\"createTopicDisabled\"]],[30,0,[\"canCreateTopicOnTag\"]],[30,0,[\"toggleTagInfo\"]],[30,0,[\"tagNotification\"]]]],null],[1,\"\\n\"],[10,0],[14,0,\"map-component map-container\"],[12],[1,\"\\n  \"],[8,[39,1],null,[[\"@mapType\"],[\"topicList\"]],null],[1,\"\\n\"],[13]],[],false,[\"discovery/navigation\",\"locations-map\"]]",
    "moduleName": "discourse/plugins/discourse-locations/discourse/templates/discovery/map.hbs",
    "isStrictMode": false
  });
});