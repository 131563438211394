define("discourse/plugins/discourse-locations/discourse/connectors/category-custom-settings/set-location", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    setupComponent(attrs) {
      if (!attrs.category.custom_fields) {
        attrs.category.custom_fields = {};
      }
    }
  };
});