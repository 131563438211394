define("discourse/plugins/discourse-locations/discourse/components/location-form", ["exports", "discourse/plugins/discourse-locations/discourse/lib/location-utilities", "discourse/lib/ajax", "@ember/object", "@ember/object/computed", "@ember/array", "@ember/service", "@glimmer/component", "@glimmer/tracking", "I18n"], function (_exports, _locationUtilities, _ajax, _object, _computed, _array, _service, _component, _tracking, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class LocationForm extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.inject]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "site", [_service.inject]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "geoLocationOptions", [_tracking.tracked], function () {
      return (0, _array.A)();
    }))();
    #geoLocationOptions = (() => (dt7948.i(this, "geoLocationOptions"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "internalInputFields", [_tracking.tracked], function () {
      return [];
    }))();
    #internalInputFields = (() => (dt7948.i(this, "internalInputFields"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "provider", [_tracking.tracked], function () {
      return "";
    }))();
    #provider = (() => (dt7948.i(this, "provider"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "hasSearched", [_tracking.tracked], function () {
      return false;
    }))();
    #hasSearched = (() => (dt7948.i(this, "hasSearched"), void 0))();
    static #_7 = (() => dt7948.g(this.prototype, "searchDisabled", [_tracking.tracked], function () {
      return false;
    }))();
    #searchDisabled = (() => (dt7948.i(this, "searchDisabled"), void 0))();
    static #_8 = (() => dt7948.g(this.prototype, "showProvider", [_tracking.tracked], function () {
      return false;
    }))();
    #showProvider = (() => (dt7948.i(this, "showProvider"), void 0))();
    static #_9 = (() => dt7948.g(this.prototype, "showGeoLocation", [_tracking.tracked], function () {
      return true;
    }))();
    #showGeoLocation = (() => (dt7948.i(this, "showGeoLocation"), void 0))();
    static #_10 = (() => dt7948.g(this.prototype, "countrycodes", [_tracking.tracked], function () {
      return [];
    }))();
    #countrycodes = (() => (dt7948.i(this, "countrycodes"), void 0))();
    static #_11 = (() => dt7948.g(this.prototype, "loadingLocations", [_tracking.tracked], function () {
      return false;
    }))();
    #loadingLocations = (() => (dt7948.i(this, "loadingLocations"), void 0))();
    static #_12 = (() => dt7948.g(this.prototype, "showLocationResults", [_tracking.tracked], function () {
      return false;
    }))();
    #showLocationResults = (() => (dt7948.i(this, "showLocationResults"), void 0))();
    static #_13 = (() => dt7948.g(this.prototype, "formStreet", [_tracking.tracked]))();
    #formStreet = (() => (dt7948.i(this, "formStreet"), void 0))();
    static #_14 = (() => dt7948.g(this.prototype, "formNeighbourhood", [_tracking.tracked]))();
    #formNeighbourhood = (() => (dt7948.i(this, "formNeighbourhood"), void 0))();
    static #_15 = (() => dt7948.g(this.prototype, "formPostalcode", [_tracking.tracked]))();
    #formPostalcode = (() => (dt7948.i(this, "formPostalcode"), void 0))();
    static #_16 = (() => dt7948.g(this.prototype, "formCity", [_tracking.tracked]))();
    #formCity = (() => (dt7948.i(this, "formCity"), void 0))();
    static #_17 = (() => dt7948.g(this.prototype, "formState", [_tracking.tracked]))();
    #formState = (() => (dt7948.i(this, "formState"), void 0))();
    static #_18 = (() => dt7948.g(this.prototype, "formCountrycode", [_tracking.tracked]))();
    #formCountrycode = (() => (dt7948.i(this, "formCountrycode"), void 0))();
    static #_19 = (() => dt7948.g(this.prototype, "formLatitude", [_tracking.tracked]))();
    #formLatitude = (() => (dt7948.i(this, "formLatitude"), void 0))();
    static #_20 = (() => dt7948.g(this.prototype, "formLongitude", [_tracking.tracked]))();
    #formLongitude = (() => (dt7948.i(this, "formLongitude"), void 0))();
    static #_21 = (() => dt7948.g(this.prototype, "geoLocation", [_tracking.tracked], function () {
      return {};
    }))();
    #geoLocation = (() => (dt7948.i(this, "geoLocation"), void 0))();
    context = null;
    showTitle = (() => (0, _computed.equal)("appType", "discourse"))();
    constructor() {
      super(...arguments);
      if (this.showInputFields) {
        this.internalInputFields = this.args.inputFields;
        this.searchDisabled = true;
        this.internalInputFields.forEach(f => {
          this[`show${f.charAt(0).toUpperCase() + f.substr(1).toLowerCase()}`] = true;
          this[`form${f.charAt(0).toUpperCase() + f.substr(1).toLowerCase()}`] = this.args[f];
          if (["street", "neighbourhood", "postalcode", "city"].includes(f)) {
            this.searchDisabled = false;
          }
        });
        if (this.args.disabledFields) {
          this.args.disabledFields.forEach(f => {
            this.set(`${f}Disabled`, true);
          });
        }
        const hasCoordinates = this.internalInputFields.indexOf("coordinates") > -1;
        if (hasCoordinates && this.args.geoLocation) {
          this.formLatitude = this.args.geoLocation.lat;
          this.formLongitude = this.args.geoLocation.lon;
        }
        const geocoding = this.siteSettings.location_geocoding;
        this.showGeoLocation = geocoding !== "none";
        this.showLocationResults = geocoding === "required";
        if (this.searchOnInit) {
          this.send("locationSearch");
        }
      }
      const siteCodes = this.site.country_codes;
      if (siteCodes) {
        this.countrycodes = siteCodes;
      } else {
        (0, _ajax.ajax)({
          url: "/locations/countries",
          type: "GET"
        }).then(result => {
          this.countrycodes = result.geo;
        });
      }
    }
    get showInputFields() {
      if (this.args.inputFieldsEnabled === false) {
        return false;
      }
      return this.args.inputFieldsEnabled || this.siteSettings.location_input_fields_enabled;
    }
    get showAddress() {
      return !this.showInputFields || this.showInputFields && this.internalInputFields.filter(f => f !== "coordinates").length > 0;
    }
    get providerDetails() {
      return _locationUtilities.providerDetails[this.provider || this.siteSettings.location_geocoding_provider];
    }
    keyDown(e) {
      if (this.showGeoLocation && e.keyCode === 13) {
        this.send("locationSearch");
      }
    }
    get searchLabel() {
      return _I18n.default.t(`location.geo.btn.${this.siteSettings.location_geocoding}`);
    }
    updateGeoLocation(gl, force_coords) {
      if (!this.showInputFields) {
        gl = this.geoLocation;
      }
      gl["zoomTo"] = true;
      if (force_coords) {
        gl.lat = this.formLatitude;
        gl.lon = this.formLongitude;
      } else {
        this.formLatitude = gl.lat;
        this.formLongitude = gl.lon;
      }
      if (gl.address && this.siteSettings.location_auto_infer_street_from_address_data && gl.address.indexOf(gl.city) > 0) {
        gl.street = gl.address.slice(0, gl.address.indexOf(gl.city)).replace(/,(\s+)?$/, "");
      }
      this.internalInputFields.forEach(f => {
        if (f === "coordinates") {
          this.formLatitude = gl.lat;
          this.formLongitude = gl.lon;
        } else {
          this[`form${f.charAt(0).toUpperCase() + f.substr(1).toLowerCase()}`] = gl[f];
        }
      });
      this.args.setGeoLocation(gl);
      this.geoLocationOptions.forEach(o => {
        (0, _object.set)(o, "selected", o["address"] === gl["address"]);
      });
    }
    static #_22 = (() => dt7948.n(this.prototype, "updateGeoLocation", [_object.action]))();
    clearSearch() {
      this.geoLocationOptions.clear();
      this.args.geoLocation = null;
    }
    static #_23 = (() => dt7948.n(this.prototype, "clearSearch", [_object.action]))();
    locationSearch() {
      let request = {};
      const searchInputFields = this.internalInputFields.concat(["countrycode", "context"]);
      searchInputFields.map(f => {
        request[f] = this[`form${f.charAt(0).toUpperCase() + f.substr(1).toLowerCase()}`];
        if (f === "coordinates") {
          request["lat"] = this.formLatitude;
          request["lon"] = this.formLongitude;
        }
      });
      if ($.isEmptyObject(request)) {
        return;
      }
      this.showLocationResults = true;
      this.loadingLocations = true;
      this.hasSearched = true;
      this.showProvider = false;
      (0, _locationUtilities.geoLocationSearch)(request, this.siteSettings.location_geocoding_debounce).then(result => {
        if (this._state === "destroying") {
          return;
        }
        if (result.provider) {
          this.provider = result.provider;
        }
        this.showProvider = result.locations.length > 0;
        this.geoLocationOptions.setObjects(result.locations);
        if (this.geoLocation) {
          this.updateGeoLocation(this.geoLocation);
        }
        this.loadingLocations = false;
      }).catch(error => {
        this.args.searchError(error);
      });
    }
    static #_24 = (() => dt7948.n(this.prototype, "locationSearch", [_object.action]))();
  }
  _exports.default = LocationForm;
});