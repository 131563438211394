define("discourse/plugins/discourse-locations/discourse/templates/components/geo-location-result", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <label {{on "click" (fn @updateGeoLocation this.location false)}}>
    {{geo-location-format this.location geoAttrs=this.geoAttrs}}
    {{#if this.showType}}
      {{#if this.location.type}}
        <div class="location-type">
          {{this.location.type}}
        </div>
      {{/if}}
    {{/if}}
  </label>
  */
  {
    "id": "ZTTK7+nh",
    "block": "[[[11,\"label\"],[4,[38,0],[\"click\",[28,[37,1],[[30,1],[30,0,[\"location\"]],false],null]],null],[12],[1,\"\\n  \"],[1,[28,[35,2],[[30,0,[\"location\"]]],[[\"geoAttrs\"],[[30,0,[\"geoAttrs\"]]]]]],[1,\"\\n\"],[41,[30,0,[\"showType\"]],[[[41,[30,0,[\"location\",\"type\"]],[[[1,\"      \"],[10,0],[14,0,\"location-type\"],[12],[1,\"\\n        \"],[1,[30,0,[\"location\",\"type\"]]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null]],[]],null],[13]],[\"@updateGeoLocation\"],false,[\"on\",\"fn\",\"geo-location-format\",\"if\"]]",
    "moduleName": "discourse/plugins/discourse-locations/discourse/templates/components/geo-location-result.hbs",
    "isStrictMode": false
  });
});