define("discourse/plugins/discourse-locations/discourse/routes/locations/users-map", ["exports", "discourse/routes/discourse"], function (_exports, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    templateName: "locations/users-map"
  });
});