define("discourse/plugins/discourse-locations/discourse/components/geo-location-result", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    tagName: "li",
    classNameBindings: [":location-form-result", "location.selected:selected"]
  });
});