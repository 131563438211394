define("discourse/plugins/discourse-locations/discourse/components/locations-map-wrapper", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <LocationsMap @mapType={{this.mapType}} />
  */
  {
    "id": "oVoZNeYq",
    "block": "[[[8,[39,0],null,[[\"@mapType\"],[[30,0,[\"mapType\"]]]],null]],[],false,[\"locations-map\"]]",
    "moduleName": "discourse/plugins/discourse-locations/discourse/components/locations-map-wrapper.hbs",
    "isStrictMode": false
  });
  class LocationMapWrapperComponent extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "mapType", [_tracking.tracked], function () {
      return "";
    }))();
    #mapType = (() => (dt7948.i(this, "mapType"), void 0))();
    constructor() {
      super(...arguments);
      this.mapType = this.args.params.mapType;
    }
  }
  _exports.default = LocationMapWrapperComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, LocationMapWrapperComponent);
});